import styled from "styled-components";
import { useState } from "react";
import {
  activeChainConfig,
  CUSTOM_SCHEMAS,
  EASContractAddress,
  getAttestationsForHash,
  getFileHash,
  timeFormatString,
} from "./utils/utils";
import dayjs from "dayjs";
import { theme } from "./utils/theme";
import { Identicon } from "./components/Identicon";
import invariant from "tiny-invariant";
import { EAS } from "@ethereum-attestation-service/eas-sdk";
import { ethers } from "ethers";
import { useAccount, useSigner } from "wagmi";
import { useModal } from "connectkit";
import { DropBlock } from "./components/DropBlock";
import { Attestation } from "./utils/types";

const Outer = styled.div``;

const Container = styled.div`
  width: 875px;
  margin: 40px auto 0;
  box-sizing: border-box;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const Title = styled.div`
  font-family: "Caveat", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 120px;
  margin-bottom: 20px;
  text-align: center;

  color: #000000;
`;

const Attester = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-top: 4px;
`;

const Time = styled.div`
  font-size: 16px;
  color: ${theme.neutrals["cool-grey-300"]};
`;

const AttestationBlock = styled.div`
  border: 1px solid ${theme.neutrals["cool-grey-100"]};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
`;

const Icon = styled(Identicon)`
  margin-right: 4px;
  margin-left: -4px;
`;

const HashContainer = styled.div`
  background-color: ${theme.neutrals["cool-grey-050"]};
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${theme.neutrals["cool-grey-100"]};
  box-sizing: border-box;
`;

const HashTitle = styled.div`
  font-weight: 800;
  color: ${theme.neutrals["cool-grey-400"]};
`;

const HashText = styled.div`
  font-size: 20px;
  word-break: break-all;
  color: ${theme.neutrals["cool-grey-800"]};
`;

const ClickToView = styled.div`
  font-size: 18px;
  color: ${theme.neutrals["cool-grey-300"]};
  text-align: center;
  margin-top: 20px;
`;

const ClickLink = styled.a`
  color: ${theme.neutrals["cool-grey-300"]};
`;

const BodyText = styled.div`
  text-align: center;
`;

const Button = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.primary["orange-vivid-300"]};
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const eas = new EAS(EASContractAddress);

export default function Index() {
  const { data: signer } = useSigner();
  const { status } = useAccount();
  const [attesting, setAttesting] = useState(false);
  const modal = useModal();

  invariant(activeChainConfig, "No active chain config");

  const [hash, setHash] = useState<string | null>(null);

  const [attestations, setAttestations] = useState<Attestation[] | null>(null);

  async function getInfoForHash(fileHash: string) {
    setAttestations(null);
    setHash(null);

    const foundAttestations = await getAttestationsForHash(fileHash);

    setHash(fileHash);
    setAttestations(foundAttestations);
  }

  return (
    <Outer>
      <Container>
        {hash === null ? (
          <>
            <Title>Verify your content.</Title>

            <DropBlock
              onDrop={async (files) => {
                const fileHash = await getFileHash(files[0]);
                await getInfoForHash(fileHash);
              }}
              subText={
                "The hashing is done locally in your browser. Your file is not uploaded anywhere."
              }
            />
          </>
        ) : hash && attestations && attestations.length === 0 ? (
          <>
            <Title>New content</Title>
            <BodyText>This content has not been attested to yet.</BodyText>

            <Centered>
              <Button
                onClick={async () => {
                  if (status !== "connected") {
                    modal.setOpen(true);
                    return;
                  }

                  setAttesting(true);
                  try {
                    invariant(signer, "signer must be defined");
                    eas.connect(signer);

                    const tx = await eas.attest({
                      data: {
                        recipient: ethers.constants.AddressZero,
                        data: hash,
                        refUID: ethers.constants.HashZero,
                        revocable: true,
                        expirationTime: 0,
                      },
                      schema: CUSTOM_SCHEMAS.CONTENT_HASH_SCHEMA,
                    });

                    await tx.wait();

                    await getInfoForHash(hash);
                  } catch (e) {}
                  setAttesting(false);
                }}
              >
                {attesting
                  ? "Attesting..."
                  : status === "connected"
                  ? "Make attestation"
                  : "Connect wallet"}{" "}
              </Button>
            </Centered>
          </>
        ) : (
          hash &&
          attestations &&
          attestations.length > 0 && (
            <>
              <Title>
                Found attestation{attestations.length > 1 ? "s" : ""}
              </Title>
              {attestations.map((attestation, i) => {
                const url = `https://${
                  activeChainConfig!.subdomain
                }easscan.org/attestation/view/${attestation.id}`;

                return (
                  <AttestationBlock
                    key={i}
                    onClick={() => {
                      window.open(url, "_blank");
                    }}
                  >
                    <Time>
                      {dayjs.unix(attestation.time).format(timeFormatString)}
                    </Time>
                    <Attester>
                      <Icon address={attestation.attester} size={30} />{" "}
                      {attestation.attester}
                    </Attester>
                    <HashContainer>
                      <HashTitle>HASH</HashTitle>
                      <HashText>{attestation.data}</HashText>
                    </HashContainer>

                    <ClickToView>
                      <ClickLink href={url}>Attestation details</ClickLink>
                    </ClickToView>
                  </AttestationBlock>
                );
              })}
            </>
          )
        )}
      </Container>
    </Outer>
  );
}
