import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  padding: 40px;
  color: #000;
  background-color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  height: 316px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;

  background-image: repeating-linear-gradient(
      0deg,
      #d1d1d1,
      #d1d1d1 15px,
      transparent 15px,
      transparent 32px,
      #d1d1d1 32px
    ),
    repeating-linear-gradient(
      90deg,
      #d1d1d1,
      #d1d1d1 15px,
      transparent 15px,
      transparent 32px,
      #d1d1d1 32px
    ),
    repeating-linear-gradient(
      180deg,
      #d1d1d1,
      #d1d1d1 15px,
      transparent 15px,
      transparent 32px,
      #d1d1d1 32px
    ),
    repeating-linear-gradient(
      270deg,
      #d1d1d1,
      #d1d1d1 15px,
      transparent 15px,
      transparent 32px,
      #d1d1d1 32px
    );
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
`;

const SubText = styled.div`
  font-family: "Nunito", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
`;

const FileTypes = styled.div`
  font-family: "Inter", sans-serif;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;

type Props = {
  onDrop: (files: File[]) => void;
  subText?: string;
};

export function DropBlock({ onDrop, subText }: Props) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <div>
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>
            Drag & Drop or <b>Choose</b> your file to hash.
          </p>
        )}

        <FileTypes>.jpeg, .pdf, .txt, .mp4, etc.</FileTypes>
        <SubText>
          This is completely private. No document is uploaded or stored.
        </SubText>
      </div>
    </Container>
  );
}
